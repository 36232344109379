* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
  margin-bottom: 3rem;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  min-width: 200px;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.stat-label {
  font-size: 1rem;
  color: #a0a0a0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.loading {
  font-size: 1.2rem;
  color: #a0a0a0;
  margin: 2rem 0;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.loading {
  animation: pulse 1.5s infinite;
}

@media (max-width: 768px) {
  .logo {
      font-size: 2rem;
  }
  
  .stat-card {
      min-width: 150px;
  }

  .stat-value {
      font-size: 1.8rem;
  }
}

.image {
  padding: 10px;
}

.channel-link {
    text-align: center;
    margin-top: 2rem;
}

.channel-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: #000000;
    background-color: #ffffff;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}


@media (max-width: 768px) {
    .channel-button {
        font-size: 0.9rem;
        padding: 0.7rem 1.3rem;
    }
}
