* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

body {
  color: #fff;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.container {
  max-width: 1200px;
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
}

.logo {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 700;
}

.subtitle {
  color: #a0a0a0;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

.stats-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

.stat-card {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  min-width: 200px;
  background: #ffffff1a;
  border-radius: 15px;
  padding: 2rem;
  transition: transform .3s;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-value {
  color: #fff;
  margin-bottom: .5rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.stat-label {
  color: #a0a0a0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;
}

.loading {
  color: #a0a0a0;
  margin: 2rem 0;
  font-size: 1.2rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  animation: 1.5s infinite pulse;
}

@media (max-width: 768px) {
  .logo {
    font-size: 2rem;
  }

  .stat-card {
    min-width: 150px;
  }

  .stat-value {
    font-size: 1.8rem;
  }
}

.image {
  padding: 10px;
}

.channel-link {
  text-align: center;
  margin-top: 2rem;
}

.channel-button {
  color: #000;
  background-color: #fff;
  border-radius: 30px;
  padding: .8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

@media (max-width: 768px) {
  .channel-button {
    padding: .7rem 1.3rem;
    font-size: .9rem;
  }
}

/*# sourceMappingURL=index.ab60908a.css.map */
